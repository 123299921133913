import React from "react"
import { EmptyProps } from "@/definitions"
import { Link } from "gatsby"

const Bottom: React.FC<EmptyProps> = () => {
  return (
    <div className="absolute bottom-0 right-0 m-2 font-sans text-xs">
      <Link to="/imprint">
        Impressum
      </Link>
    </div>
  )
}

export default Bottom
